// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PatchV1AccountsAccountidMutationRequest,
  PatchV1AccountsAccountidMutationResponse,
  PatchV1AccountsAccountidPathParams,
  PatchV1AccountsAccountid400,
  PatchV1AccountsAccountid401,
  PatchV1AccountsAccountid403,
  PatchV1AccountsAccountid404,
  PatchV1AccountsAccountid500,
} from '../models/PatchV1AccountsAccountid'
import type { UseMutationOptions } from '@tanstack/react-query'

type PatchV1AccountsAccountidClient = typeof client<
  PatchV1AccountsAccountidMutationResponse,
  | PatchV1AccountsAccountid400
  | PatchV1AccountsAccountid401
  | PatchV1AccountsAccountid403
  | PatchV1AccountsAccountid404
  | PatchV1AccountsAccountid500,
  PatchV1AccountsAccountidMutationRequest
>
type PatchV1AccountsAccountid = {
  data: PatchV1AccountsAccountidMutationResponse
  error:
    | PatchV1AccountsAccountid400
    | PatchV1AccountsAccountid401
    | PatchV1AccountsAccountid403
    | PatchV1AccountsAccountid404
    | PatchV1AccountsAccountid500
  request: PatchV1AccountsAccountidMutationRequest
  pathParams: PatchV1AccountsAccountidPathParams
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<PatchV1AccountsAccountidClient>>
  client: {
    parameters: Partial<Parameters<PatchV1AccountsAccountidClient>[0]>
    return: Awaited<ReturnType<PatchV1AccountsAccountidClient>>
  }
}
/**
 * @link /v1/accounts/:accountId
 */
export function usePatchV1AccountsAccountid(
  options: {
    mutation?: UseMutationOptions<
      PatchV1AccountsAccountid['response'],
      PatchV1AccountsAccountid['error'],
      {
        accountId: PatchV1AccountsAccountidPathParams['accountId']
        data: PatchV1AccountsAccountid['request']
      }
    >
    client?: PatchV1AccountsAccountid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ accountId, data }) => {
      const res = await client<
        PatchV1AccountsAccountid['data'],
        PatchV1AccountsAccountid['error'],
        PatchV1AccountsAccountid['request']
      >({
        method: 'patch',
        url: `/v1/accounts/${accountId}`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
