// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClubMutationRequest,
  PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClubMutationResponse,
  PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClubPathParams,
  PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClubHeaderParams,
  PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClub401,
  PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClub403,
} from '../models/PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClub'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClubClient =
  typeof client<
    PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClubMutationResponse,
    | PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClub401
    | PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClub403,
    PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClubMutationRequest
  >
type PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClub = {
  data: PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClubMutationResponse
  error:
    | PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClub401
    | PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClub403
  request: PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClubMutationRequest
  pathParams: PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClubPathParams
  queryParams: never
  headerParams: PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClubHeaderParams
  response: Awaited<
    ReturnType<PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClubClient>
  >
  client: {
    parameters: Partial<
      Parameters<PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClubClient>[0]
    >
    return: Awaited<
      ReturnType<PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClubClient>
    >
  }
}
/**
 * @link /v1/surveys/:surveyId/question/shareofwallet/update-sow-main-club
 */
export function usePutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClub(
  options: {
    mutation?: UseMutationOptions<
      PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClub['response'],
      PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClub['error'],
      {
        surveyId: PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClubPathParams['surveyId']
        headers?: PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClub['headerParams']
        data: PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClub['request']
      }
    >
    client?: PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClub['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, data, headers }) => {
      const res = await client<
        PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClub['data'],
        PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClub['error'],
        PutV1SurveysSurveyidQuestionShareofwalletUpdateSowMainClub['request']
      >({
        method: 'put',
        url: `/v1/surveys/${surveyId}/question/shareofwallet/update-sow-main-club`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
